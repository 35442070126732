/** @format */

import { getClearingFieldList, getNavList } from '@/components/moduladmin/ModulAdminFields.js'

export default {
  auth_request(state) {
    state.authStatus = 'loading'
  },
  auth_success(state, { token, refreshToken, user }) {
    state.authStatus = 'success'
    state.error_msg = ''
    state.token = token
    state.refreshToken = refreshToken
    state.user = user
    state.tokenRefreshed = false
  },
  tokenRefreshed(state, { status }) {
    state.tokenRefreshed = status
  },
  auth_error(state, { errorMessage }) {
    state.authStatus = 'error'
    state.error_msg = errorMessage
  },
  logout(state) {
    state.authStatus = ''
    state.token = ''
    state.refreshToken = ''
    state.notifications = []
  },
  setTableList(state, tableList) {
    state.tableList = tableList
  },
  clearNotifications(state) {
    state.notifications = 0
  },
  update_notifications(state, payload) {
    state.notifications = payload
  },
  update_paantragsvorlagen(state, data) {
    state.PAAntragsvorlagen = data
  },
  update_major_list(state, payload) {
    state.PAMajorList = payload
  },
  update_pl_list(state, payload) {
    state.PAPLList = payload
  },
  update_main_topics(state, data) {
    state.PAMainTopics = data
  },
  update_pruefer_list(state, payload) {
    state.PrueferList = payload
  },
  update_person_list(state, payload) {
    state.PersonList = payload
  },
  update_modul_list(state, data) {
    state.PAModulList = data
  },
  update_pos(state, payload) {
    state.POs = payload
  },
  update_stundenplan_list(state, payload) {
    state.stundenplanList = payload
  },
  update_settings(state, payload) {
    state.settings = payload
  },
  update_pruefungsform_list(state, data) {
    state.pruefungsformList = data
  },
  update_bewertungsschema_list(state, data) {
    state.bewertungsschemaList = data
  },
  update_pruefungsformtemplate_list(state, data) {
    state.pruefungsformTemplateList = data
  },
  update_language_list(state, data) {
    state.languageList = data
  },
  update_fachcode_list(state, data) {
    state.fachcodeList = data
  },
  update_lehrform_list(state, data) {
    state.lehrformList = data
  },
  update_semester_list(state, data) {
    state.semesterList = data
  },
  update_studiengang_list(state, data) {
    state.studiengangList = data
  },
  update_pomodul_list(state, data) {
    state.POModulList = data
  },
  reset_pomodul_list(state) {
    state.POModulList = []
  },
  loadingStatus(state, newStatus) {
    state.loadingStatus = newStatus
  },
  set_prevent_unload: (state, value) => (state.preventUnload = value),
  update_current_semester_info(state, payload) {
    state.currentSemesterInfo = payload?.semesterInfo
  },
  toggle_nav(state) {
    state.Sidebar_drawer = !state.Sidebar_drawer
  },
  update_user_extra_roles(state, payload) {
    state.user.extra_roles = payload.extra_roles
  },
  update_breadcrumb_items(state, payload) {
    state.breadcrumbItems = payload.items
  },
  reset_breadcrumb_items(state) {
    state.breadcrumbItems = []
  },
  update_moduladmin_po_list(state, payload) {
    state.poList = payload
  },
  update_clearingeintrag_list(state, payload) {
    switch (payload.action) {
      case 'append':
        state.clearingeintragList.data?.splice(payload.index ?? 0, 0, payload.item)
        break
      case 'update': {
        const index = state.clearingeintragList.data.findIndex(x => x.id === payload.item.id)
        if (index > -1)
          state.clearingeintragList.data?.splice(index, 1, payload.item)
        break
      }
      case 'refresh':
      default:
        state.clearingeintragList = payload
        break
    }

    state.loadingStatus = false
  },
  reset_clearingeintrag_list(state) {
    state.clearingeintragList = []
  },
  update_clearingadmin_modul_list(state) {
    state.loadingStatus = false
  },
  update_clearingadmin_nav_list(state, payload) {
    const poId = payload?.poId
    state.clearingNavList = getNavList(poId, payload?.data)
  },
  update_clearingadmin_clearingfiled_list(state, payload) {
    state.clearingFieldList = getClearingFieldList(payload.poId, payload?.data)
  },
  update_clearing_po_permissions(state, payload) {
    state.clearingPOPermissions = payload.permissions
  },
  update_clearing_po_status(state, payload) {
    state.clearingPOStatus = payload.status
  },
  updatingStatus(state, payload) {
    state.updatingStatus = payload
  },
  update_hover_status(state, payload) {
    state.hoverStatus = payload
  },
  reset_clearing_navlist(state) {
    state.clearingNavList = []
  },
  reset_app_bar(state) {
    state.showAppBar = true
  },
  show_snackbar(state, payload) {
    state.snackBarOptions = payload
  },
  update_clearing_field(state, payload) {
    const modulId = payload.modulId
    const objId = payload.objId
    const objType = payload.objType
    const fieldName = payload.fieldName
    const index = state.clearingFieldList?.findIndex(
      x => String(x.modulId) === String(modulId)
      && x.objType === objType
      && x.objId === objId
      && x.fieldName === fieldName,
    )
    if (index > -1)
      state.clearingFieldList[index].content = payload.textContent
  },
  update_clearingnachricht_list(state, payload) {
    const clearingnachricht = payload.item

    if (clearingnachricht) {
      const index = state.clearingeintragList.data.findIndex(x => x.id === clearingnachricht.CeId)
      const indexComment = state.clearingeintragList.data[index].comments?.findIndex(x => x.id === clearingnachricht.id)
      if (indexComment < 0)
        state.clearingeintragList.data[index].comments.push(clearingnachricht)
      else
        state.clearingeintragList.data[index].comments.splice(indexComment, 1, clearingnachricht)
    }
  },
  reset_clearing_list(state) {
    state.filterOptionsClearingeintragList = null
    state.clearingeintragList = []
    state.clearingAdminModulList = []
    state.clearingNavList = []
    state.clearingFieldList = []
  },
  change_clearing_modullist_start(state, payload) {
    state.clearingModulListStart = payload
  },
  show_overlay(state, payload) {
    state.overlayOptions = payload
  },
  hightlight_clearingeintrag(state, payload) {
    state.hightlightedClearingeintragId = payload?.id
  },
  delete_clearingeintrag(state, payload) {
    if (payload.item) {
      if (payload.action === 'delete') {
        const index = state.clearingeintragList.data.findIndex(x => x.id === payload.item.id)
        if (index > -1)
          state.clearingeintragList.data.splice(index, 1)
      }
    }
  },
  delete_clearingnachricht(state, payload) {
    const clearingnachricht = payload.item

    if (clearingnachricht) {
      const index = state.clearingeintragList.data.findIndex(x => x.id === clearingnachricht.CeId)
      const indexComment = state.clearingeintragList.data[index].comments?.findIndex(x => x.id === clearingnachricht.id)
      if (indexComment > -1)
        state.clearingeintragList.data[index].comments.splice(indexComment, 1)
    }
  },
  update_filter_options(state, payload) {
    const filterOptions = payload ?? {}
    for (const area in filterOptions) {
      const areaFilterOptions = filterOptions[area]
      if (areaFilterOptions !== undefined && areaFilterOptions !== null && areaFilterOptions.constructor === Object) {
        // if (!state._filterOptions[area]) {
        //   state._filterOptions[area] = {}
        // }
        for (const key in areaFilterOptions) {
          // if (!state._filterOptions[area][key]) {
          //   state._filterOptions[area][key] = areaFilterOptions[key]
          // } else {
          state._filterOptions[area][key] = areaFilterOptions[key]
          // }
        }
      }
      else {
        state._filterOptions[area] = areaFilterOptions
      }
    }
  },
  reset_filter_options(state, payload) {
    const areas = payload?.areas ?? []
    for (const area of areas) {
      const areaFilterOptions = state._filterOptions[area]
      if (areaFilterOptions) {
        for (const key in areaFilterOptions) {
          delete state._filterOptions[area][key]
        }
      }
    }
  },
}
